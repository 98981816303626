.sidebar-header {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.sidebar-logo-container {
  background-color: #0d0d2b;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
}

.sidebar-icon {
  width: 55%;
  height: 80px;
}

.main-title {
  font-family: "Arial Black", sans-serif; /* Bold and clean sans-serif font */
  font-size: 18px; /* Adjust size as needed */
  font-weight: bold;
  color: #ffffff; /* White text color */
  letter-spacing: 1px;
  text-transform: uppercase; /* Ensure text is all caps */
}
.header-title {
  font-family: "Arial Black", sans-serif; /* Bold and clean sans-serif font */
  font-size: 24px; /* Adjust size as needed */
  font-weight: bold;
  color: #000; /* White text color */
  letter-spacing: 1px;
  text-transform: uppercase; /* Ensure text is all caps */
  margin: 0;
  padding: 0;
}
.sub-title {
  font-size: 14px;
  color: #b0b3c6;
}

.highlight {
  color: #4a90e2; /* Blue color for "Securely" */
  font-weight: bold;
}
